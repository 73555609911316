var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-treeview',{attrs:{"active":_vm.active,"items":_vm.root.items,"item-children":"items","item-text":"title","item-key":"uid","color":"success","open-on-click":"","transition":""},on:{"update:active":function($event){_vm.active=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [(!item.items)?_c('v-icon',[_vm._v(" mdi-book-open ")]):_vm._e()]}},{key:"append",fn:function(ref){
var item = ref.item;
return [(item.deleted)?_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-bookmark-remove-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$localize('deleted')))])]):_vm._e(),_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((item.deleted ? _vm.menuItemsRemoved : _vm.menuItemsExist),function(menu,i){return _c('v-list-item',{key:i,attrs:{"link":""},on:{"click":function($event){return _vm.menuClick(menu, item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$localize(menu.title)))])],1)}),1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }